import key from './key';
import { Resource } from '@ekuaibao/fetch';
var newPage = new Resource('/api/menu/v2/home/page');
var mallCardData = new Resource('/api/message/v1/messageCenterConfig/getOperation');
var businesssummary = new Resource('/api/menu/v2/businesssummary');
export function getLayoutConfigInfo() {
    return {
        type: key.GET_LAYOUT_CONFIG_INFO,
        payload: newPage.GET('/config/info')
    };
}
export function getMallCardData() {
    return {
        type: key.GET_MALL_CARD_DATA,
        payload: mallCardData.POST('', { source: 'DESKTOP', mallEntrance: true })
    };
}
export function getBusinesssummary(params) {
    return {
        type: key.GET_BUSINESSSUMMARY,
        payload: businesssummary.POST('', params)
    };
}
export function getBusinesssummaryMyBill(params) {
    return {
        type: key.GET_BUSINESSSUMMARYMYBILL,
        payload: businesssummary.POST('/desktop/mybill', params)
    };
}
export function changeLayoutConfig(params) {
    return {
        type: key.CHANGE_LAYOUT_CONFIG,
        payload: newPage.PUT('', params)
    };
}
export function getHomePageLayoutConfig() {
    return {
        type: key.GET_HOMEPAGE_LAYOUT,
        payload: newPage.GET('')
    };
}
export function setDefaultLayout() {
    return {
        type: key.SET_DEFAULT_LAYOUT,
        payload: newPage.PUT('/default')
    };
}
