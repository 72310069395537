export var ID = '@new-homepage';
export default {
    ID: ID,
    GET_LAYOUT_CONFIG_INFO: ID + "/GET_LAYOUT_CONFIG_INFO",
    GET_MALL_CARD_DATA: ID + "/GET_MALL_CARD_DATA",
    GET_BUSINESSSUMMARY: ID + "/GET_BUSINESSSUMMARY",
    GET_BUSINESSSUMMARYMYBILL: ID + "GET_BUSINESSSUMMARYMYBILL",
    CHANGE_LAYOUT_CONFIG: ID + "/CHANGE_LAYOUT_CONFIG",
    GET_HOMEPAGE_LAYOUT: ID + "/GET_HOMEPAGE_LAYOUT",
    SET_DEFAULT_LAYOUT: ID + "/SET_DEFAULT_LAYOUT"
};
