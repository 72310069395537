var _a;
import { app as api } from '@ekuaibao/whispered';
import loadable from '@loadable/component';
import * as actions from './new-homepage.action';
import { getQueryString } from './utils/utilSource';
export default [
    {
        id: '@new-homepage',
        path: '/new-homepage',
        ref: '/',
        reducer: function () { return import('./new-homepage.reducer'); },
        onload: function () {
            return import('./new-homepage-view');
        },
        'set:default:layout': function () { return api.dispatch(actions.setDefaultLayout()); },
        'get:mall:cardData': function () { return api.dispatch(actions.getMallCardData()); },
        'get:businesssummary': function (params) { return api.dispatch(actions.getBusinesssummary(params)); },
        'get:businesssummary:myBill': function (params) { return api.dispatch(actions.getBusinesssummaryMyBill(params)); },
        'get:location:search': function (params) { return getQueryString(params); },
        'get:homepage:layout:config': function () { return api.dispatch(actions.getHomePageLayoutConfig()); },
        'get:layout:config:info': function () { return api.dispatch(actions.getLayoutConfigInfo()); },
        'change:layout:config': function (params) { return api.dispatch(actions.changeLayoutConfig(params)); }
    },
    {
        point: '@@layers',
        prefix: '@new-homepage',
        onload: function () { return import('./layers'); }
    },
    {
        point: '@@menus',
        onload: function () { return [
            {
                id: 'newHomePage',
                permissions: ['SYS_ADMIN'],
                href: '/new-homepage'
            }
        ]; }
    },
    {
        resource: '@new-homepage',
        value: (_a = {},
            _a['elements/StandardCardList'] = loadable(function () { return import('./elements/StandardCardList'); }),
            _a)
    }
];
