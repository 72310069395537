import { __assign, __awaiter, __generator } from "tslib";
import { get } from 'lodash';
import { app as api } from '@ekuaibao/whispered';
export function setCardsChecked(cards, checkedIds) {
    if (checkedIds.length) {
        cards.forEach(function (b) {
            b.checked = checkedIds.includes(b.id) ? true : false;
        });
    }
    else {
        cards.forEach(function (item) { return (item.checked = false); });
    }
}
var IdMapInfo = {
    RECORD_EXPENDS: {
        key: 'RECORD_EXPENDS',
        style: {
            background: 'rgb(31,16,71)'
        },
        leftStyle: {
            width: '100%',
            height: '100%'
            // background: `url(../../../images/record-left.png) left top / 296px 62px no-repeat`
        },
        rightStyle: {
            width: '100%',
            height: '100%'
            // background: `url(../../../images/record-right.png) right bottom / 140px 44px no-repeat`
        },
        title: i18n.get('随手记'),
        subTitle: i18n.get('随时随地记录每笔费用'),
        iconName: '#EDico-record5'
    },
    INVOICE_CHECK: {
        key: 'INVOICE_CHECK',
        style: {
            background: 'linear-gradient(225deg,rgba(76,39,152,1) 0%,rgba(49,20,97,1) 100%)'
        },
        leftStyle: {
            width: '100%',
            height: '100%'
            // background: `url(../../../images/invoice-left.png) left top / 280px 62px no-repeat`
        },
        rightStyle: {
            width: '100%',
            height: '100%'
            // background: `url("../../../images/invoice-right.png") right bottom / 180px 59px no-repeat`
        },
        title: i18n.get('发票验真'),
        subTitle: i18n.get('实时验证发票'),
        iconName: '#EDico-scan2'
    }
};
export function cardsIdMapInfo(config) {
    if (config === void 0) { config = []; }
    var visibleCards = [];
    visibleCards = config
        .map(function (item) {
        return IdMapInfo[item];
    })
        .filter(function (item) { return item; });
    return visibleCards;
}
export function getQueryString(params) {
    var props = params.props, name = params.name;
    var str = get(props, 'location.search', '');
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = str.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}
export function fnStandardPageTrack(event, options) {
    var _a, _b;
    if (window === null || window === void 0 ? void 0 : window.TRACK) {
        var staff = (_a = api.getState()['@common'].userinfo) === null || _a === void 0 ? void 0 : _a.staff;
        window.TRACK(event, __assign({ platForm: 'Web', appVersion: api.getState()['@common'].powers.Universal, source: window.__PLANTFORM__, staffId: staff === null || staff === void 0 ? void 0 : staff.userId, corpId: (_b = staff === null || staff === void 0 ? void 0 : staff.corporation) === null || _b === void 0 ? void 0 : _b.id }, options));
    }
}
export function fastRouter(item) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, isChecked;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = item.key;
                    switch (_a) {
                        case 'RECORD_EXPENDS': return [3 /*break*/, 1];
                        case 'INVOICE_CHECK': return [3 /*break*/, 2];
                    }
                    return [3 /*break*/, 4];
                case 1:
                    api.go("/record-expends");
                    api.invokeService('@layout5:active:menu', 'recordExpends');
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, api.invokeService('@corporation-info:checkInvoiceSettingInfo')];
                case 3:
                    isChecked = _b.sent();
                    if (!!isChecked) {
                        return [2 /*return*/];
                    }
                    api.go("/serach-engine?activeTabKey=checkInvoice");
                    api.invokeService('@layout5:active:menu', 'searchMenu');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
